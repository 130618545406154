import React, { useEffect, useState } from "react";
import BotonTurno from "../../components/BotonTurno";
import Calendario from "../../components/Calendario";
import FixedCTA from "../../components/FixedCTA";
import Selector from "../../components/Selector";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import APIManager from '../../managers/APIManager';
import CalendarioDeSalas from "../../components/CalendarioDeSalas";
import { useInterval } from '../../lib/useInterval';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ReservaDeSala({usuario, ...props }) {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const horariosIniciales = [
    {horas: 8, minutos: 0, nombre: '08:00'}, {horas: 8, minutos: 30, nombre: '08:30'},
    {horas: 9, minutos: 0, nombre: '09:00'}, {horas: 9, minutos: 30, nombre: '09:30'},
    {horas: 10, minutos: 0, nombre: '10:00'}, {horas: 10, minutos: 30, nombre: '10:30'},
    {horas: 11, minutos: 0, nombre: '11:00'}, {horas: 11, minutos: 30, nombre: '11:30'},
    {horas: 12, minutos: 0, nombre: '12:00'}, {horas: 12, minutos: 30, nombre: '12:30'},
    {horas: 13, minutos: 0, nombre: '13:00'}, {horas: 13, minutos: 30, nombre: '13:30'},
    {horas: 14, minutos: 0, nombre: '14:00'}, {horas: 14, minutos: 30, nombre: '14:30'},
    {horas: 15, minutos: 0, nombre: '15:00'}, {horas: 15, minutos: 30, nombre: '15:30'},
    {horas: 16, minutos: 0, nombre: '16:00'}, {horas: 16, minutos: 30, nombre: '16:30'},
    {horas: 17, minutos: 0, nombre: '17:00'}, {horas: 17, minutos: 30, nombre: '17:30'},
    {horas: 18, minutos: 0, nombre: '18:00'}, {horas: 18, minutos: 30, nombre: '18:30'},
    {horas: 19, minutos: 0, nombre: '19:00'}, {horas: 19, minutos: 30, nombre: '19:30'},
    {horas: 20, minutos: 0, nombre: '20:00'}, {horas: 20, minutos: 30, nombre: '20:30'},
  ];

  const duracionesIniciales = [
    { intervalo: 'PT30M', horas: 0.5, nombre: '30 min' },
    { intervalo: 'PT1H', horas: 1, nombre: '1 h' },
    { intervalo: 'PT1H30M', horas: 1.5, nombre: '1:30 hs' },
    { intervalo: 'PT2H', horas: 2, nombre: '2 hs' },
    { intervalo: 'PT2H30M', horas: 2.5, nombre: '2:30 hs' },
    { intervalo: 'PT3H', horas: 3, nombre: '3:00 hs' },
    { intervalo: 'PT3H30M', horas: 3.5, nombre: '3:30 hs' },
    { intervalo: 'PT4H', horas: 4, nombre: '4:00 hs' },
    { intervalo: 'PT4H30M', horas: 4.5, nombre: '4:30 hs' },
    { intervalo: 'PT5H', horas: 5, nombre: '5:00 hs' },
    { intervalo: 'PT5H30M', horas: 5.5, nombre: '5:30 hs' },
    { intervalo: 'PT6H', horas: 6, nombre: '6:00 hs' },
    { intervalo: 'PT6H30M', horas: 6.5, nombre: '6:30 hs' },
    { intervalo: 'PT7H', horas: 7, nombre: '7:00 hs' },
    { intervalo: 'PT7H30M', horas: 7.5, nombre: '7:30 hs' },
    { intervalo: 'PT8H', horas: 8, nombre: '8:00 hs' },
    { intervalo: 'PT8H30M', horas: 8.5, nombre: '8:30 hs' },
    { intervalo: 'PT9H', horas: 9, nombre: '9:00 hs' },
    { intervalo: 'PT9H30M', horas: 9.5, nombre: '9:30 hs' },
    { intervalo: 'PT10H', horas: 10, nombre: '10:00 hs' },
    { intervalo: 'PT10H30M', horas: 10.5, nombre: '10:30 hs' },
    { intervalo: 'PT11H', horas: 11, nombre: '11:00 hs' },
    { intervalo: 'PT11H30M', horas: 11.5, nombre: '11:30 hs' },
    { intervalo: 'PT12H', horas: 12, nombre: '12:00 hs' },
  ];

  const [horarios, setHorarios] = useState(horariosIniciales);
  const [duraciones, setDuraciones] = useState(duracionesIniciales);

  const [espacio, setEspacio] = useState(sistema.actual && sistema.actual.espacio ? sistema.espacios.find(item => item.id === sistema.actual.espacio.id) : sistema.espacio);
  const [espacios, setEspacios] = useState(sistema.espacios);
  const [sala, setSala] = useState(sistema.actual && sistema.actual.sala ? sistema.actual.sala : null);
  const [salas, setSalas] = useState(sistema.actual.salas ? sistema.actual.salas : []);
  const [salasFolded, setSalasFolded] = useState(true);
  const [fecha, setFecha] = useState(sistema.actual && sistema.actual.fecha ? sistema.actual.fecha : new Date());
  const [hora, setHora] = useState(sistema.actual && sistema.actual.hora ? horarios.find(item => item.nombre === sistema.actual.hora.nombre) : horarios[0]);
  const [duracion, setDuracion] = useState(sistema.actual && sistema.actual.duracion ? duraciones.find(item => item.nombre === sistema.actual.duracion.nombre) : duraciones[0]);
  const [salasLoading, setSalasLoading] = useState(false);
  const [costo, setCosto] = useState(0);
  const [saldo, setSaldo] = useState(usuario.creditos.balance)
  const [ahora, setAhora] = useState(new Date());

  const [horaMaxima, setHoraMaxima] = useState(20); // Cambiar por el de la sede cuando sea variable
  const [minutoMaximo, setMinutoMaximo] = useState(30); // Cambiar por el de la sede cuando sea variable
  const [horaMinima, setHoraMinima] = useState(8); // Cambiar por el de la sede cuando sea variable
  const [minutoMinimo, setMinutoMinimo] = useState(30); // Cambiar por el de la sede cuando sea variable

  useEffect(() => {
    if (!!hora && !!horarios && (horarios.length > 0)) {
      const nuevoHorario = horarios.find((item) => { return item.nombre === hora.nombre });
      if (!nuevoHorario) {
        setHora(horarios && (horarios.length > 0) ? horarios[0] : null);
      } else {
        setHora(nuevoHorario);
      }
    }
  }, [horarios, hora]);

  useEffect(() => {
    const nuevaDuracion = duraciones.find((item) => { return item.nombre === duracion.nombre });
    if (!nuevaDuracion) {
      const duracionDefault = duraciones && (duraciones.length > 0) ? duraciones[0] : null;
      setDuracion(duracionDefault);
      if (!duracionDefault) {
        setFecha(moment(fecha).add(1, "day").toDate()); // Si no hay duraciones para hoy, arrancamos mañana
      }
    } else {
      setDuracion(nuevaDuracion);
    }
  }, [duraciones, duracion]);

  useEffect(() => {
    const today = new Date(ahora);
    if (!!hora) {
      today.setHours(hora.horas);
      today.setMinutes(hora.minutos);
    }

    var horaFinal = new Date(today.getFullYear(), today.getMonth(), today.getDate(), horaMaxima, minutoMaximo, 0);
    const diferencia = (horaFinal - today) / 36e5;

    setDuraciones(duracionesIniciales.filter((item, index) => {return index < (diferencia * 2)}))
  }, [hora, ahora, horaMaxima, minutoMaximo]);

  const horarioDeApertura = () => {
    const horaApertura = new Date();
    horaApertura.setHours(espacio.horario.apertura.substr(0, 2));
    horaApertura.setMinutes(espacio.horario.apertura.substr(3, 2));
    horaApertura.setSeconds(0);

    return horaApertura;
  }

  const horarioDeCierre = () => {
    const horaCierre = new Date();
    horaCierre.setHours(espacio.horario.cierre.substr(0, 2));
    horaCierre.setMinutes(espacio.horario.cierre.substr(3, 2));
    horaCierre.setSeconds(0);

    return horaCierre;
  }

  const horariosHabiles = () => {
    const horaApertura = horarioDeApertura();
    const horaCierre = horarioDeCierre();

    let horaActual = moment(horaApertura);

    const listado = [];

    while (horaActual < horaCierre) {
      listado.push({horas: horaActual.hours(), minutos: horaActual.minutes(), nombre: horaActual.format('HH:mm')});
      horaActual = horaActual.add(30, 'minutes');
    }
    return listado;
  }

  useEffect(() => {
    // Hay que cargar las salas
    if (!!espacio && !!espacio.activo && !!espacio.horario && !!espacio.horario.apertura && !!espacio.horario.cierre && fecha) {
      sistema.actual.espacio = espacio;
      // Seteamos apertura y cierre
      setHoraMinima(moment(horarioDeApertura()).hours());
      setMinutoMinimo(moment(horarioDeApertura()).minutes());

      setHoraMaxima(moment(horarioDeCierre()).hours());
      setMinutoMaximo(moment(horarioDeCierre()).minutes());

      setSalasLoading(true);
      setSalasFolded(true);

      APIManager.getSalasConReservas(espacio, fecha, (data) => {
        if (sistema.actual.sala && (sistema.actual.sala.space_id === espacio.id)) {
          sistema.actual.sala = data.listado.find(item => item.id === sistema.actual.sala.id);
        } else {
          sistema.actual.sala = data.listado[0];
        }

        // Actualizamos el estado
        setSalas(data.listado);
        setSalasLoading(false);
        setSala(sistema.actual.sala);

        const fechaReferencia = new Date(fecha);
        if (fechaReferencia && fechaReferencia.getFullYear() === ahora.getFullYear()
          && fechaReferencia.getMonth() === ahora.getMonth()
          && fechaReferencia.getDate() === ahora.getDate()) {
            // Es para HOY, tomamos los horarios disponibles
            const today = new Date(ahora);
            //var horaInicial = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 45, 0);

            var horaInicial = new Date(today.getFullYear(), today.getMonth(), today.getDate(), moment(horarioDeApertura()).hours(), moment(horarioDeApertura()).minutes()+15, 0);
            const diferencia = (today - horaInicial) / 36e5;

            // setHorarios(horariosIniciales.filter((item, index) => { return index > (diferencia * 2) }));

            setHorarios(horariosHabiles().filter((item, index) => { return index > (diferencia * 2) }));
        } else {
          //setHorarios(horariosIniciales);
          setHorarios(horariosHabiles());
        }

      });

    }
  },[espacio,fecha, ahora]);

  const fechaDisponible = (fechaAVerificar) => {
    // Devuelve si la fecha esta disponible
    const hoy = new Date();
    hoy.setHours(0,0,0,0);
    const aMoment = moment(fechaAVerificar);

    // Preliminar: Devolvemos true si es hoy, futuro y no es domingo
    //return aMoment.isSameOrAfter(hoy) && (aMoment.day() !== 0) /*domingo*/

    // Final: Devolvemos true si es hoy, futuro y dia de la semana permitido para el espacio
    const esHoyOFuturo = aMoment.isSameOrAfter(hoy);
    const permitidoEnEspacio = espacio.horario.semana[aMoment.day()];
    // Ademas revisamos que se encuentre permitido por la membresia
    const permitidoEnMembresia = !usuario.membresia.restricciones || !usuario.membresia.restricciones.diasDeLaSemana || usuario.membresia.restricciones.diasDeLaSemana[aMoment.day()];
    return esHoyOFuturo && permitidoEnEspacio && permitidoEnMembresia;
  }

  const confirmarReserva = () => {
    if (!sistema.actual) {
      if (!localStorage.actual) {
        sistema.actual = {};
      } else {
        sistema.actual = JSON.parse(localStorage.actual);
      }
    }
    sistema.actual.accion = 'reservarSala';
    sistema.actual.fecha = fecha;
    sistema.actual.espacio = espacio;
    sistema.actual.sala = sala;
    sistema.actual.hora = hora;
    sistema.actual.duracion = duracion;
    delete(sistema.actual.usuarioAsignado);
    delete(sistema.actual.puesto);
    delete(sistema.actual.oficina);
    delete(sistema.actual.mesa);
    delete(sistema.actual.sector);
    delete(sistema.actual.turno);
    delete(sistema.actual.fechaHasta);
    delete(sistema.actual.reservas);
    delete(sistema.actual.id);
    delete(sistema.actual.salas);
    localStorage.actual = JSON.stringify(sistema.actual);

    // Check de viabilidad:
    // 1- Hora de inicio no menor a 15' previos a la hora actual
    // 2- Hora de fin no posterior a las 21hs

    sistema.actual.errores = [];

    const fechaAVerificar = new Date(sistema.actual.fecha);
    fechaAVerificar.setHours(0);
    fechaAVerificar.setMinutes(0);
    fechaAVerificar.setSeconds(0);
    fechaAVerificar.setMilliseconds(0);

    const fechaDeHoy = new Date();
    fechaDeHoy.setHours(0);
    fechaDeHoy.setMinutes(0);
    fechaDeHoy.setSeconds(0);
    fechaDeHoy.setMilliseconds(0);

    const horaInicial = new Date(sistema.actual.fecha);
    horaInicial.setHours(sistema.actual.hora.horas);
    horaInicial.setMinutes(sistema.actual.hora.minutos);
    horaInicial.setSeconds(0);
    horaInicial.setMilliseconds(0);

    const horaFinal = moment(horaInicial).add(sistema.actual.duracion.horas, 'h').toDate();

    let horaDeInicioPermitida = new Date(new Date() - (15 * 60 * 1000)); // Hace 15'

    if (fechaAVerificar > fechaDeHoy) {
      horaDeInicioPermitida = new Date(fechaAVerificar);  // Las 8:30 del dia, cambiar cuando sea variable
      horaDeInicioPermitida.setHours(horaMinima);
      horaDeInicioPermitida.setMinutes(minutoMinimo);
    }

    const horaDeFinPermitida = new Date(horaInicial.getFullYear(), horaInicial.getMonth(), horaInicial.getDate(), 21, 0, 0);

    if (horaInicial < horaDeInicioPermitida) {
      // Anterior a 15' antes del horario actual
      sistema.actual.error = {message: 'Tu reserva comienza antes del horario permitido', code: 'ERR_TOO_EARLY'};
    }

    if (horaFinal > horaDeFinPermitida) {
      // Finaliza luego de las 21hs
      sistema.actual.errores.push({message: 'Supera el horario de cierre', code: 'ERR_TOO_LATE'});
    }

    if (sala && sala.reservas && sala.reservas.length > 0) {
      // Si hay una reserva que coincide en horarios, no se puede reservar

      const reservasConflictivas = sala.reservas.filter((reserva) => {
        const chunks = Object.values(reserva.reservationChunks);

        return !!chunks.find((chunk) => {
          const chunkDate = moment(chunk.datetime.date).toDate();
          return (chunkDate >= horaInicial) && (chunkDate < horaFinal); // El chunk esta dentro del horario a reservar?
         });
      });
      if (reservasConflictivas.length > 0) {
        sistema.actual.errores.push({ message: 'Hay una reserva en el mismo horario', code: 'ERR_OCCUPIED' });
      }

    }

    if (saldo < 0) {
      // No te alcanza el credito
      sistema.actual.errores.push({ message: 'No tienes suficientes creditos para realizarla', code: 'ERR_NO_CREDITS' });
    }

    if (!sistema.actual.errores || (sistema.actual.errores.length === 0)) {
      // Si no hay errores, vamos a confirmar
      history.push('/confirmar');
    } else {
      // Hay errores, los mostramos
      handleShow();
    }

  }

  useEffect(() => {
    sistema.actual.costo = sala ? (sala.costoPorHora * (duracion? duracion.horas : 0)) : 0;
    setCosto(sistema.actual.costo);
    setSaldo(usuario.creditos.balance - sistema.actual.costo);
  }, [sala, duracion, usuario.creditos.balance]);

  const iconoPara = (error) => {
    let img = '';
    switch (error.code) {
      case 'ERR_TOO_EARLY':
      case 'ERR_TOO_LATE':
        img = "icon--time-black.svg";
        break;
      case 'ERR_OCCUPIED':
        img = "icon--calendar.svg";
        break;
      case 'ERR_NO_CREDITS':
        img = "icon--money.svg";
        break;
      default: ;
    }
    if (!!img) {
      return <img className="error-icon" src={"/img/" + img} alt="" />;
    }
  }

  const infoDeSala = (item) => {
    if (!item) {
      return '';
    }

    let $infoExtra = '';
    $infoExtra += item.costoPorHora ? item.costoPorHora + ' cr/h' : '';
    $infoExtra += item.capacidad ? ' ' + item.capacidad + ' per' : '';
    return $infoExtra;
  }

  const comprarCreditos = () => {
    history.push('/creditos');
  }

  useEffect(() => {
    sistema.actual.tipoDeSala = 'SALA';
    if (!espacio || !espacio.activo || !espacio.horario || !espacio.horario.apertura || !espacio.horario.cierre) {
      // No hay data habil del espacio
      history.push('/');
    }
  })

  return (
    (!!espacio && !!espacio.activo && !!espacio.horario && !!espacio.horario.apertura && !!espacio.horario.cierre) &&
    <div className={"reserva-de-sala-page"}>
      <Modal className="error-modal" show={show} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered={true}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <img className="error-title-icon" src="/img/icon--attention.svg" alt=""/>
            <h1 className="error-title" >Atención</h1>
            <h4 className="error-subtitle">Tu reserva no se ha podido efectuar tal cual la has solicitado</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 className="error-instructions">Por favor revisa los siguientes datos para continuar con tu reserva de sala</h4>
          {sistema.actual.errores && sistema.actual.errores.length > 0 && sistema.actual.errores.map((error, index) => {
            return <div key={index} className="error-item">{ iconoPara(error) } <p  data-codigo={error.code} key={index}>{error.message}</p></div>
          })}
        </Modal.Body>
        <Modal.Footer className="error-footer">
          <Button className="error-button" onClick={handleClose}>Modificar mi reserva</Button>
        </Modal.Footer>
      </Modal>
      <TituloConBack titulo="Reserva de Espacio privado" />
      <div className="wrapper--scrollable-content__layout">
        <div className="wrapper--scrollable-content__column">
          <div className='titulo'>Eligí ubicación, día y horario</div>
          <Selector titulo="Sede" selectedItem={espacio} items={espacios} icon={<img src="img/icon--location-grey.svg" alt="ubicacion"/>} onChange={setEspacio} folded={true}/>
          <div className="reserva-de-sala__horario-duracion">
            <div className="selector-combo selector--horario">
              <h3 className="selector-combo__titulo">Horario</h3>
              <Selector titulo="Horario" selectedItem={hora} items={horarios} icon="" onChange={setHora} folded={true}/>
            </div>
            <div className="selector-combo selector--duracion">
              <h3 className="selector-combo__titulo">Duración</h3>
              <Selector titulo="Duración" selectedItem={duracion} items={duraciones} icon="" onChange={setDuracion} folded={true}/>
            </div>
          </div>
          <div className="selector-combo selector--sala">
            <h3 className="selector-combo__titulo">Salas</h3>
            <Selector loading={salasLoading} titulo="Sala" selectedItem={sala} items={salas} icon="" showInfo={infoDeSala} onChange={setSala} folded={salasFolded} />
            {/* <div className="reserva-de-sala__info-sala"  onClick={() => {history.push('/sala/'+sala.id)}}>Info de sala <button className="reserva-de-sala__info-sala-button">!</button></div> */}
          </div>
          <button className="control--info-de-salas" onClick={() => {history.push('/infodesalas')}}>Ver información de salas</button>
          <Calendario {...props} fecha={fecha} type={props.isPortrait ? 'week' : 'month'} onChange={setFecha} esFechaDisponible={fechaDisponible}/>
        </div>
        <div className="wrapper--scrollable-content__column">
        <p className="reservas-de-salas__instrucciones">Cheque&aacute; si tu sala est&aacute; disponible</p>
          <CalendarioDeSalas salas={salas} horarios={horariosIniciales} usuario={usuario} {...props} />
        </div>
      </div>
      {(saldo < 0) && <FixedCTA className="CTA__comprar" titulo={costo + " Créditos"} leyendaPre="Créditos " leyendaBold="insuficientes" leyendaPost="" label="Comprar créditos" onClick={comprarCreditos} />}
      {(saldo >= 0) && <FixedCTA disabled={!fechaDisponible(fecha)} titulo={costo + " Créditos"} leyendaPre="Te quedarán" leyendaBold={saldo} leyendaPost="créditos" label="Continuar" onClick={confirmarReserva}/>}

    </div>
  );
}
