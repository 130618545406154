import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sistema } from '../../model/Sistema';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import TituloConBack from '../../components/TituloConBack';
import DetalleConIconoYTexto from '../../components/DetalleConIconoYTexto';
import FixedCTA from '../../components/FixedCTA';
import ListaDeMaquineros from '../../components/ListaDeMaquineros';

export default ({ usuario, ...props }) => {

  const history = useHistory();
  let { id } = useParams();
  const [experiencia, setExperiencia] = useState(null);
  const [saldo, setSaldo] = useState(usuario.creditos.balance)


  if (!sistema.actual.id && id) {
    sistema.actual.id = id;
  }
  if (!sistema.actual.id) {
    history.push('/experiencias');
  }

  useEffect(() => {
    getUIData('getExperiencia', {id: sistema.actual.id}, setExperiencia);
  }, []);

  const registroRealizado = (response) => {
    sistema.actual.experiencia = experiencia;
    sistema.usuario.creditos.balance -= response.data.costo;
    sistema.saveActual();
    history.push('/exitoexperiencia');
  }

  const registrarme = (event) => {
    sistema.actual.accion = 'realizarInscripcion';
    confirmarClick(event);
  }

  const cancelarme = (event) => {
    sistema.actual.accion = 'cancelarInscripcion';
    confirmarClick(event);
  }

  const confirmarClick = (event) => {
    const postData = {
      eventoID: experiencia.id
    };
    sendRequest('post','eventos',sistema.actual.accion, postData, registroRealizado);
  }

  props.setSeccion('/experiencias');

  const inscripcionHabilitada = (usuario.habilitado && experiencia && (usuario.creditos.balance >= experiencia.costo));

  return (experiencia &&
    <div className="detalle-de-experiencia">
      <TituloConBack titulo={experiencia.nombre}>
        <div className="detalle-de-experiencia__icons">
          {/* Botones de compartir y guardar como favorito
          <button className="detalle-de-experiencia__control" ><DetalleConIconoYTexto icono="share-alt" texto="Compartir" /></button>
          <button className="detalle-de-experiencia__control"><DetalleConIconoYTexto icono={<img src="/img/icon--heart-black.svg" alt="" />} texto="Guardar" /></button>
          */}
        </div>
      </TituloConBack>
      <div className="detalle-de-experiencia__info">
        <h1 className="detalle-de-experiencia__titulo">{experiencia.nombre}</h1>
        {experiencia.linkURL &&
        <DetalleConIconoYTexto className="detalle-de-experiencia__link" icono={<img className="icon--info" src="/img/icon--info-warning.svg" alt=""></img>} texto={<a className="detalle__texto" href={experiencia.linkURL} >Más info en éste link</a>} />
        }
        <DetalleConIconoYTexto className="detalle-de-experiencia__fecha" icono={<img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>} texto={experiencia.fecha} />
        <DetalleConIconoYTexto className="detalle-de-experiencia__duracion" icono="" texto={"Duración: " + experiencia.duracion} />
        {experiencia.inscripto && experiencia.linkPrivado &&
        <DetalleConIconoYTexto className="detalle-de-experiencia__linkPrivado" icono={<img className="icon--chevron" src="/img/chevron--orange.svg" alt=""></img>} texto={<a className="detalle__texto" href={experiencia.linkPrivado} >Acceso on-line en éste link</a>} />
        }
        <DetalleConIconoYTexto className="detalle-de-experiencia__ubicacion" icono={<img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>} texto={experiencia.ubicacion} />
      </div>
      <div className="detalle-de-experiencia__showcase">
        <div className="detalle_de_experiencia__imagen-wrapper"><img className="detalle_de_experiencia__imagen" src={experiencia.imagen} alt=""></img></div>
        <div className="detalle-de-experiencia__detalles">
          <h2 className="detalle-de-experiencia__subtitulo">Detalles</h2>
          <p className="detalle-de-experiencia__descripcion">{experiencia.descripcion}</p>
        </div>
      </div>
      {!props.isPortrait &&
        <div className="detalle-de-experiencia__asistentes">
          <h2 className="detalle-de-experiencia__asistentes__titulo">{"Asistentes (" + experiencia.asistentes.length + ")"}</h2>
          <ListaDeMaquineros items={experiencia.asistentes} />
        </div>
      }
      {experiencia.inscripto
      ? <FixedCTA className="cancelar" label="Cancelar Inscripción" onClick={cancelarme}>
          <div className="detalle-de-experiencia__FixedCTA-info">
            {experiencia.linkURL &&
            <DetalleConIconoYTexto className="detalle-de-experiencia__link" icono={<img className="icon--info" src="/img/icon--info-warning.svg" alt=""></img>} texto={<a className="detalle__texto" href={experiencia.linkURL} >Más info en éste link</a>} />
            }
            <DetalleConIconoYTexto className="detalle-de-experiencia__fecha" icono={<img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>} texto={experiencia.fecha} />
            <DetalleConIconoYTexto className="detalle-de-experiencia__duracion" icono="" texto={"Duración: " + experiencia.duracion} />
            {experiencia.linkPrivado &&
            <DetalleConIconoYTexto className="detalle-de-experiencia__linkPrivado" icono={<img className="icon--chevron" src="/img/chevron--orange.svg" alt=""></img>} texto={<a className="detalle__texto" href={experiencia.linkPrivado} >Acceso on-line en éste link</a>} />
            }
            <DetalleConIconoYTexto className="detalle-de-experiencia__ubicacion" icono={<img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>} texto={experiencia.ubicacion} />
          </div>
        </FixedCTA>
      : <FixedCTA disabled={!inscripcionHabilitada} className={(usuario.status !== 'approved') ? 'sin-leyenda' : ''} titulo={experiencia.costo + ' Créditos'} leyendaPre="te quedarán" leyendaBold={usuario.creditos.balance - experiencia.costo} leyendaPost="créditos" label="Registrarme" onClick={registrarme}>
          <div className="detalle-de-experiencia__FixedCTA-info">
            {experiencia.linkURL &&
            <DetalleConIconoYTexto className="detalle-de-experiencia__link" icono={<img className="icon--info" src="/img/icon--info-warning.svg" alt=""></img>} texto={<a className="detalle__texto" href={experiencia.linkURL} >Más info en éste link</a>} />
            }
            <DetalleConIconoYTexto className="detalle-de-experiencia__fecha" icono={<img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>} texto={experiencia.fecha} />
            <DetalleConIconoYTexto className="detalle-de-experiencia__duracion" icono="" texto={"Duración: " + experiencia.duracion} />
            <DetalleConIconoYTexto className="detalle-de-experiencia__ubicacion" icono={<img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>} texto={experiencia.ubicacion} />
          </div>
        </FixedCTA>
      }
    </div>
  )
}