export default class Usuario {
  constructor(data) // v2
  {
    if (!data) {
      data = {};
    }
    this.id = data.id;
    this.activo = data.activo ?? data.is_active;
    this.aceptado = data.aceptado;
    this.email = data.email;
    this.nombres = data.nombres;
    this.apellidos = data.apellidos;
    this.emailDeContacto = data.contact_email;
    this.telefono = data.telefono;
    this.descripcion = this.nombres + (this.apellidos ? ' ' + this.apellidos : '') + '(' + this.emailDeContacto + ')';
    this.avatar = data.imagen;
    this.profile_photo = data.imagen;
    this.creditos = data.creditos;
    this.membresia = data.membresia;
    this.contrato = data.contrato;
    this.status = data.status;
    this.tieneOficina = data.tieneOficina;
    this.aspectosProfesionales = data.professional_aspects;
    this.professionalKeywords = data.professional_keywords;
    this.professionalHighlights = data.professional_highlights;
    this.profesion = data.profesion;
    this.residence = data.residence;
    this.aptitudes = data.aptitudes;
    this.tipoDeDocumento = data.tipoDeDocumento;
    this.numeroDeDocumento = data.numeroDeDocumento;
    this.genero = data.genero;
    this.nacionalidad = data.nacionalidad;
    this.fechaDeNacimiento = data.fechaDeNacimiento;
    if (!!data.empresa) {
      this.empresa = data.empresa;
    }
    this.habilitado = !!this.contrato && !!this.contrato.fechaDeAprobacion && (this.status === 'approved');
    this.contratoAdminAccess = data.contratoAdminAccess;
    this.accesoACuenta = data.accesoACuenta;
    this.completitudDePerfil = data.completitudDePerfil;
    this.tieneHijos = data.tieneHijos;
    this.comoNosConocio = data.comoNosConocio;
  }

  static newFromV1Data(data) // v1
  {
    const result = new this();
    if (!data) {
      data = {};
    }
    result.id = data.id;
    result.email = data.email;
    result.nombres = data.first_name;
    result.apellidos = data.last_name;
    result.emailDeContacto = data.contact_email;
    result.descripcion = result.nombres + (result.apellidos ? ' ' + result.apellidos : '') + '(' + result.emailDeContacto + ')';
    result.avatar = data.profile_picture;
    result.profile_photo = data.profile_picture;
    result.creditos = data.credits;
    result.membresia = data.membresia;
    result.contrato = data.contrato;
    result.status = data.status;
    result.tieneOficina = data.tieneOficina;
    result.aspectosProfesionales = data.professional_aspects;
    result.aptitudes = data.aptitudes;

    return result;
  }

}
