import { LinearProgress } from '@mui/material';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

export default ({usuario, ...props}) => {

  const completitudDePerfil = () => {
    // Valores posibles: 0, 30, 50, 70, 100
    if (!usuario.nombres || !usuario.apellidos) {
      return 30;
    }
    if (!usuario.membresia || (!usuario.status === 'approved')) {
      return 50;
    }
    if (!usuario.aspectosProfesionales) {
      return 70;
    }
    return 100;
  }

  return (
    (usuario.completitudDePerfil === 100) ? null :
    <div className="menu-perfilCTA">
      <span className="menu-perfilCTA__title">Completá tu perfil &gt;</span>
      <LinearProgress variant="determinate" className='menu-perfilCTA__progress' value={usuario.completitudDePerfil}></LinearProgress>
      {/* <div className={"menu-perfilCTA__progress progress--"+usuario.completitudDePerfil} ></div> */}
      <p className="menu-perfilCTA__text">Para nosotros es importante que completes tus datos, así podremos ofrecerte una mejor experiencia</p>
    </div>
  );
}
